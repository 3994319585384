@font-face {
  font-family: 'Poppins-Black';
  src: local('Poppins-Black'), url(../fonts/Poppins-Black.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-BlackItalic';
  src: local('Poppins-BlackItalic'),
    url(../fonts/Poppins-BlackItalic.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'),
    url(../fonts/Poppins-SemiBold.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url(../fonts/Poppins-Bold.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url(../fonts/Poppins-Light.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url(../fonts/Poppins-Medium.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-MediumItalic';
  src: local('Poppins-MediumItalic'),
    url(../fonts/Poppins-MediumItalic.ttf) format('tff');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url(../fonts/Poppins-Regular.ttf) format('tff');
}


body {
  font-family: 'Poppins-Regular', sans-serif;
  background-color: blue;
}

a{
  text-decoration: none !important;
}

.input-code {
  color: 'pink';
  border: 0px solid;
}

.MuiTabs-centered .MuiTab-textColorPrimary {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 16px;
}

.MuiTabs-root .MuiTab-labelIcon {
  font-weight: 500;
  text-transform: capitalize;
  font-size: 16px;
}

.MuiGrid-spacing-xs-3 {
  width: calc(100% + 12px) !important;
}

.makeStyles-tab-25 {
  text-transform: capitalize;
}

.makeStyles-tab-25:focus img {
  text-transform: capitalize;
  background: #fff;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #8596e1 !important;
}

.MuiAccordionroot {
  &.MuiAccordion-root:before {
    background-color: rgba(0, 0, 0, 0);
  }

  
}


.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #8596e1 !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active svg {
  color: #fff !important;
}

.MuiTableCell-root .client-name {
  background-color: #106ee8;
  border-radius: 50%;
  padding: 8px 6px;
  color: #fff;
  margin: 0 4px;
  font-size: 12px;
}

.MuiTableCell-root .plan-type-Strategic {
  background-color:skyblue;
  padding: 8px 6px;
  border-radius: 25%;
  color: #fff;
  margin: 0 4px;
  font-size: 12px;
}

.MuiTableCell-root .plan-type-Foundational {
  background-color: lightgreen;
  border-radius: 25%;
  padding: 8px 6px;
  color: #fff;
  margin: 0 4px;
  font-size: 12px;
}

.MuiTableCell-root .plan-type-Elite {
  background-color: red;
  border-radius: 25%;
  padding: 8px 6px;
  color: #fff;
  margin: 0 4px;
  font-size: 12px;
}



.makeStyles-root-22 .MuiTabs-root .MuiTabs-scroller .Mui-selected {
  color: #ffffff;
  height: 160px;
  opacity: 1;
  font-weight: 500;
  background-color: #106ee8;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.MuiMenu-list .MuiList-padding:focus {
  border: none !important;
  outline: none !important;
}

.element {
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 2px 0 rgba(0, 0, 0, 0.01);*/
  padding: 8px !important;
  border: 1px solid #d9d9d9;
}

th.MuiTableCell-alignLeft {
  text-align: left;
  font-size: 13px !important;
  font-weight: 600 !important;
}

th.MuiTableCell-alignLeft .MuiTableSortLabel-root:hover {
  color: #fff !important;
}

.topbarTabs .MuiTabs-indicator {
  display: none;
}

.topbarTabs .MuiTab-textColorPrimary.Mui-selected{
  color: #106ee8;
  padding-bottom: 2px;
  border-bottom-color: #106ee8;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  width: fit-content;
}
// .topbarTabs .MuiTab-textColorInherit {
//   color: inherit;
//   opacity: 1;
// }
// .topbarTabs #simple-tab-1:visited,.topbarTabs #simple-tab-2:visited {
//   color: #106ee8;
//   padding-bottom: 2px;
//   border-bottom-color: #106ee8;
//   border-bottom-style: solid;
//   border-bottom-width: 4px;
//   width: fit-content;
// }

.topbarTabs .MuiTab-root {
  padding: 6px 4px;
  margin: 0 8px;
  font-family: 'Poppins';
}

@media (min-width: 600px) {
  .MuiTab-root {
    min-width: auto !important;
  }
}

.strategyModal {
  width: 600px !important;
  position: absolute;
  min-height: 475px;
  border-radius: 8px;
  background-color: #fff;
}

.strategyModal:hover,
.strategyModal:focus {
  outline: none;
}

.topbarMenuProfile .MuiMenu-paper {
  top: 60px !important;
}

.topbarMenuProfile .MuiMenu-paper .MuiTypography-button {
  text-transform: capitalize;
  font-family: 'Poppins';
}

.topbarMenuProfile .MuiMenu-paper .MuiMenuItem-root {
  padding-top: 1px !important;
}

.topbarMenuProfile .MuiMenu-paper .MuiListItemIcon-root {
  min-width: 32px;
}

.topbarMenuProfile .MuiMenu-paper .MuiLinearProgress-root {
  width: 100%;
  background-color: #e1e1e1 !important;
  border-radius: 50px;
  margin-top: 8px;
}

.topbarMenuProfile .MuiMenu-paper .MuiLinearProgress-barColorPrimary {
  background-color: #e45500 !important;
  border-radius: 50px;
}

.modal-video-close-btn {
  top: -24px !important;
  right: -4px !important;
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
  &:hover,
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

.tooltip-popover {
  .MuiPopover-paper {
    padding: 12px !important;
    background: #106ee8 !important;
    border-radius: 8px !important;
    // top: 58px !important;
    // left: 985px !important;
    width: 260px !important;
    .MuiCard-root {
      background: #106ee8;
      .MuiTypography-body2 {
        color: #fff;
        width: 230px;
      }
      .MuiButton-outlinedPrimary {
        color: #fff;
        border: 1px solid #fff;
        margin-right: 8px;
        margin-top: 12px;
        &:first-child {
          margin-left: 0;
        }
        .MuiSvgIcon-root {
          font-size: 14px;
          margin-bottom: 2px;
        }
        .MuiButton-label {
          font-size: 12px;
          text-transform: capitalize;
        }
      }
    }
  }
}

.third-tooltip {
  .MuiPopover-paper {
    width: 190px !important;
    .MuiCard-root {
      .MuiTypography-body2 {
        width: 145px;
      }
    }
  }
}

.second-tooltip {
  .MuiPopover-paper {
    margin-top: -32px;
  }
}

.notificationPopup {
  .MuiPopover-paper {
    margin-top: 20px;
    // margin-left: 50px;
    padding: 24px;
    max-height: 800px;
    width: 450px;
    background: #f6f6f6 !important;
    .MuiCard-root {
      box-shadow: none;
      background: #f6f6f6;
      .MuiButton-containedSecondary {
        background-color: #e45500;
        text-transform: capitalize;
        font-size: 13px;
        &:hover,
        &:focus {
          background-color: #dc004e;
        }
      }
      .MuiCard-root {
        padding: 12px 20px;
        margin: 8px 0;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.19);


        .MuiAvatar-root {
          font-size: 13px;
          width: 32px;
          height: 32px;
        }
      }
      .MuiBadge-colorSecondary {
        background-color: #00d962;
      }
    }
  }
}

.resourcesDrawer {
  .MuiBackdrop-root {
    background-color: transparent;
  }
// .MuiAccordion-root:before{
//   background-color: rgba(0, 0, 0, 0);
//   height: 0;
// }
.MuiAccordion-root:before {
    top: 0px;
    left: 0;
    right: 0;
    height: 0;
    content: "";
    opacity: 1;
    position: absolute;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: rgba(0, 0, 0, 0.0);
}

  .MuiDrawer-paper {
    // top: 30%;
    width: 400px;
    padding: 24px 32px;
    padding-bottom: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    ::-webkit-scrollbar {
      width: 4px;
      height: 2px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
      border-radius: 50px;
    }
    .MuiAppBar-colorDefault {
      background-color: transparent;
      box-shadow: none;
      margin-top: 24px;
      .MuiTab-root {
        text-transform: capitalize;
        // font-family: 'Poppins-SemiBold';
        font-family: 'Poppins';
        border-bottom: 2px solid #d2d2d2;
        font-weight: 600;
        .MuiTab-fullWidth {
          border-bottom: 2px solid #d2d2d2;
        }
      }
    }
    .react-swipeable-view-container {
      margin-bottom: 80px !important;
    }
    .extenal-links-section {
      margin: 0 -24px 16px;
     
      .links-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 16px;
        margin: 8px 0;
        margin-right:20px;
        
        padding-left:35px;
       
        &:hover {
          box-shadow: 0 0px 7px 0 rgba(0, 0, 0, 0.02),
            0 1px 7px 0 rgba(0, 0, 0, 0.12);
        }
      }
    }
  }
}


.landing-page-custom-tabs {
  button {
    &.MuiButtonBase-root {
      height: auto !important;
      .MuiTab-wrapper {
        font-family: 'Poppins';
      }
      &:focus {
        height: auto !important;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .MuiTabs-root {
    margin-right: 24px;
    .MuiTabScrollButton-root {
      width: auto;
      svg {
        display: none;
      }
    }
  }
  .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
  }
}

.MuiTable-root {
  .MuiTableHead-root {
    .MuiTableCell-root {
      .MuiButtonBase-root {
        font-family: 'Poppins';
      }
    }
  }
  .MuiTableBody-root {
    .MuiTableCell-root {
      font-family: 'Poppins';
    }
  }
}

.MuiContainer-root {
  .MuiTypography-root {
    font-family: 'Poppins';
  }
}

.MuiFormControl-root {
  .MuiFormLabel-root {
    font-family: 'Poppins';
  }
}

.MuiMenuItem-root {
  font-family: 'Poppins';
}

// Poppins fonts
// h1, h2, h3, h4, h5, h6, p, a, div, span {
//   font-family: 'Poppins' !important;
// }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.custom-link:hover {
  cursor: pointer;
}